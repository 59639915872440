:root {
  --padding-s: 8px;
  --padding-m: 16px;
  --padding-l: 24px;
  --padding-xl: 32px;

  --lilac: #966b9d;
  --rose: #c98686;
  --apricot: #f2b880;
  --seashell: #fff4ec;
  --white: #fff;
  --pink: #e7cfbc;
}